<template>
    <div>
        <!-- 头部横幅 -->
        <div class="banner">
            <el-carousel :interval="40000" type="card">
                <el-carousel-item>
                    <img src="@/assets/images/0000.webp" alt=""/>
                </el-carousel-item>
                <el-carousel-item>
                    <img src="@/assets/images/1111.png" alt=""/>
                </el-carousel-item>
                <el-carousel-item>
                    <img src="@/assets/images/2222.jpg" alt=""/>
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- 头部介绍 -->
        <div class="produce">
            <div class="one">
                <h3>客户导向</h3>
                <p>
                  我们的首要目标是满足客户的需求和期望。我们深入了解客户需求，以客户的利益为重，为客户提供高品质、高性价比的产品和服务。
                </p>
            </div>
            <div class="two">
                <h3>创新发展</h3>
                <p>
                  我们持续不断地推陈出新，倡导创新发展。我们鼓励员工积极创新，提倡尝试新想法和新技术，以不断提高产品和服务质量。
                </p>
            </div>
            <div class="three">
                <h3>诚信服务</h3>
                <p>
                  我们重视诚信服务，坚持诚信做人、诚信做事，为客户提供真实、可靠的产品和服务。
                </p>
            </div>
        </div>

        <!-- 产品展示 -->
        <div class="show">
            <h2>产品展示<span>OURPRODUCTS</span></h2>
            <div class="all_pic">
                <ul class="lt_pic">
                    <li>
                        <img src="@/assets/images/70.webp"/>
                        <p>
                            大屏展示<br/><br/><br/>
                            <span>Home Daily</span>
                        </p>
                    </li>
                    <li>
                        <img src="@/assets/images/71.webp"/>
                        <p>
                            数据管理<br/><br/><br/>
                            <span>Home Strage</span>
                        </p>
                    </li>
                    <li>
                            <img src="@/assets/images/72.webp"/>
                            <p>
                                数据分析<br/><br/><br/>
                                <span>Home Accessories</span>
                            </p>
                    </li>
                    <li>
                        <img src="@/assets/images/73.webp"/>
                        <p>
                            报表管理<br/><br/><br/>
                            <span>Kitchen Catering</span>
                        </p>
                    </li>
                </ul>
            <div class="rt_pic">
                <div>
                    <a href="#">
                        <img src="@/assets/images/74.jpg"/>
                        <p>
                            专业方案<br/>
                            <span>Bedding Article</span><br/>
                            <button type="submit">READ MORE</button>
                        </p>
                    </a>
                </div>
            </div>
            </div>
        </div>

        <!-- 关于我们 -->
        <div class="about">
            <div class="about_conten">
                <h2 class="title_about">关于我们</h2>
                <p>
                    成都弈尘云网络科技有限公司是一家专业的软件开发公司，主要提供小程序、网站、管理系统、大屏显示和定制开发等一系列服务。我们拥有一支高素质、专业化的研发团队，以创新、高效、质量为核心价值观，为客户提供最优质的解决方案。
                </p>
                <a href="about.html#/about">READ MORE</a>
            </div>
        </div>

        <!-- 新闻动态 -->
      <!-- <div class="container new">
          <h2>新闻动态<span>NEWS CENTER</span></h2>
          <div class="list01">
              <img src="@/assets/images/pic06.jpg"/>
              <ul>
                  <li>
                      <a href="#"><span class="li_title">[行业资讯]</span></a
                      ><a href="#"></a><a href="#" class="content">家纺行业的四大走向</a>
                      <span class="time">2014-10-20</span>
                  </li>
                  <li>
                      <a href="#"><span class="li_title">[行业资讯]</span></a>
                      <a href="#"></a
                      ><a href="#" class="content">家纺行业:从价值链的薄弱环节找到</a>
                      <span class="time">2014-10-20</span>
                  </li>
                  <li>
                      <a href="#"><span class="li_title">[行业资讯]</span></a>
                      <a href="#"></a
                      ><a href="#" class="content">2009年净利润同比增长34.4</a>
                      <span class="time">2014-10-20</span>
                  </li>
                  <li>
                      <a href="#"><span class="li_title">[行业资讯]</span></a>
                      <a href="#"></a
                      ><a href="#" class="content">家电涨价与降价背后的营销逻辑</a>
                      <span class="time">2014-10-20</span>
                  </li>
                  <li>
                      <a href="#"><span class="li_title">[行业资讯]</span></a
                      ><a href="#"></a>
                      <a href="#" class="content">高科技家电未来都很低碳</a>
                      <span class="time">2014-10-20</span>
                  </li>
                  <li>
                      <a href="#"><span class="li_title">[行业资讯]</span></a
                      ><a href="#"></a>
                      <a href="#" class="content">源码网加盟“绿书签”行动</a>
                      <span class="time">2014-10-20</span>
                  </li>
              </ul>
          </div>
          <div class="list02">
              <img src="@/assets/images/pic07.jpg"/>
              <ul>
                  <li>
                      <a href="#"><span class="li_title">[公司动态]</span></a
                      ><a href="#" class="content">迎“十一”主题优惠即将开幕</a>
                      <span class="time">2014-10-20</span>
                  </li>
                  <li>
                      <a href="#"><span class="li_title">[公司动态]</span></a
                      ><a href="#" class="content">天右家纺驻外办事处相继成立</a>
                      <span class="time">2014-10-20</span>
                  </li>
                  <li>
                      <a href="#"><span class="li_title">[公司动态]</span></a
                      ><a href="#" class="content">天右家纺驻外办事处相继成立</a>
                      <span class="time">2014-10-20</span>
                  </li>
                  <li>
                      <a href="#"><span class="li_title">[公司动态]</span></a>
                      <a href="#" class="content">继往开来 搏击10</a
                      ><span class="time">2014-10-20</span>
                  </li>
                  <li>
                      <a href="#"><span class="li_title">[公司动态]</span></a>
                      <a href="#" class="content">天右组团参加第十七届中国（上海）</a
                      ><span class="time">2014-10-20</span>
                  </li>
                  <li>
                      <a href="#"><span class="li_title">[公司动态]</span></a
                      ><a href="#" class="content">天右家居体验馆盛大开业</a>
                      <span class="time">2014-10-20</span>
                  </li>
              </ul>
          </div>
          <div class="list03">
              <img src="@/assets/images/pic08.jpg"/>
              <ul>
                  <li>
                      <a href="#"><span class="li_title">[媒体热点]</span></a>
                      <a href="#" class="content">有意实施行业内重组 蕴藏可升值资</a
                      ><span class="time">2014-10-20</span>
                  </li>
                  <li>
                      <a href="#"><span class="li_title">[媒体热点]</span></a>
                      <a href="#" class="content">端午节家电大力促销</a
                      ><span class="time">2014-10-20</span>
                  </li>
                  <li>
                      <a href="#"><span class="li_title">[媒体热点]</span></a>
                      <a href="#" class="content">家纺与电影</a
                      ><span class="time">2014-10-20</span>
                  </li>
                  <li>
                      <a href="#"><span class="li_title">[媒体热点]</span></a>
                      <a href="#" class="content">家纺行业：从价值链的薄弱环节找到</a
                      ><span class="time">2014-10-20</span>
                  </li>
                  <li>
                      <a href="#"><span class="li_title">[媒体热点]</span></a
                      ><a href="#" class="content">当前中国家纺市场现状及前景预测</a>
                      <span class="time">2014-10-20</span>
                  </li>
                  <li>
                      <a href="#"><span class="li_title">[媒体热点]</span> </a>
                      <a href="#" class="content">家纺行业的四大走向</a
                      ><span class="time">2014-10-20</span>
                  </li>
              </ul>
          </div>
      </div> -->
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        data() {
            return {};
        },
        created() {
            // this.sss();
        },
        methods: {
            sss() {
                axios.get("www.baidu.com").then((res)=>{

                })
            }

        },
    };
</script>

<style scoped>
/* h2样式  */
 /*  h2 {
  font-weight: normal;
    font-size: 27px;
    padding-bottom: 50px;
    margin-top: 50px;*/
    /* background: url(@/assets/images/nav_bg02.png) no-repeat; 
    background: url(@/assets/images/nav_bg02.png);
} */

    /* 头部横幅 */
    .banner {
        width: 100%;
        height: 350px;
        position: relative;
        background-color: rgba(0, 0, 0, 0.8);
        padding: 20px 0;
        box-sizing: border-box;
    }
    .banner img {
        width: 100%;
        height: 100%;
    }
    .el-carousel{
        width: 100%;
        margin:0 auto;
        overflow: hidden;
    }
    /* 头部介绍 */
    .produce {
        color: #f8faff;
        text-align: center;
        font-size: 18px;
        padding-top: 30px;
        text-align: center;
        display: flex;
        justify-content: center;
    }
    .produce h3 {
        font-size: 18px;
        margin-bottom: 10px;
    }
    .produce p {
        font-size: 12px;
        line-height: 30px;
    }
    .produce .one,
    .produce .two,
    .produce .three {
        width: 400px;
        height: 210px;
        display: inline-block;
        text-align: center;
        overflow: hidden;
        box-sizing: border-box;
        padding: 40px;
    }
    .produce .one {
        background: url(~@/assets/images/01.jpg) no-repeat;
        float: left;
    }
    .produce .two {
        background: url(~@/assets/images/02.jpg) no-repeat;
        float: left;
    }
    .produce .three {
        background: url(~@/assets/images/03.jpg) no-repeat;
    }
 
    /* 产品展示 */
    .show{
        padding: 0 5%;
    }
     .show h2 span {
        color: #bbb8b5;
        font-size: 18px;
    }
    .all_pic{
        display: flex;
        justify-content: center;
    }
    .show .lt_pic{
        width: 40%;
        display: flex;
        flex-wrap: wrap;
    }
    .show .lt_pic li{
        margin: 0 10px 10px 0;
        box-sizing: border-box;
        width: 271px;
        height: 276px;
        position: relative;
    }
    .show .lt_pic li img {
        width: 100%;
        height: 100%;
    }
    .show .lt_pic li p {
        position: absolute;
        width: 100%;
        background: rgba(255, 255, 255, 0.8);
        top: 0;
        left: 0;
        font-size: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        display: none;
    }
    .show .lt_pic li p span {
        font-size: 18px;
        color: #15bded;
    }
    .show .lt_pic li:hover p{
            display: flex;
    }


    .show .rt_pic{
            width: 40%;
        }
   
    .show .rt_pic div {
        width: 600px;
        height: 580px;
        position: relative;
    }

    .show .rt_pic div img {
        width: 600px;
        height: 580px;
    }

    .show .rt_pic div p {
        width: 600px;
        height: 580px;
        position: absolute;
        padding-top: 200px;
        bottom: 0;
        background: rgba(255, 255, 255, 0.8);
        text-align: center;
        box-sizing: border-box;
        font-size: 23px;
        display: none;
    }

    .show .rt_pic div button {
        border: 1px #000 solid;
        outline: 0;
        background: transparent;
        border-radius: 20px;
        width: 138px;
        height: 46px;
        margin-top: 30px;
        cursor: pointer;
    }

    .show .rt_pic div p span {
        font-size: 18px;
        color: #15bded;
    }

    .show .rt_pic div:hover p {
        display: block;
    }

    /* 关于我们 */
    .about {
        background: url(~@/assets/images/banner3.jpg) no-repeat center;
        height: 508px;
        width: 100%;
        text-align: center;
        margin-top: 20px;
    }

    .title_about {
        font-weight: normal;
        font-size: 27px;
        padding: 50px 0 40px 0;
        background: url(~@/assets/images/nav_bg02.png) no-repeat center 45px;
    }

    .about .about_conten {
        width: 670px;
        margin: 0 auto;
        color: #696d70;
    }

    .about .about_conten h1 {
        color: #352e1e;
        padding-top: 80px;
        padding-bottom: 44px;
        font-weight: normal;
    }

    .about .about_conten p {
        line-height: 25px;
        font-size: 15px;
    }

    .about .about_conten a {
        color: #fff;
        display: inline-block;
        width: 155px;
        height: 46px;
        line-height: 46px;
        border-radius: 30px;
        background: #25bbce;
        margin-top: 50px;
    }
 @media screen and (max-width:500px) {
            .banner{
                height: 150px;
            }
            .el-carousel ,.el-carousel__container{
                height: 120px !important;
            }
            ::v-deep .el-carousel__mask{
                display: none !important;
            }
            .banner img{
                height: 90px;
            }
            .produce{
                flex-direction: column;
            }
            .produce div{
                width: 100% !important;
            }
            .show{
                display: none;
            }
            .about .about_conten{
                width: 100%;
            }
        }
    /* 新闻动态 */
    .new {
        height: 721px;
        box-sizing: border-box;
    }

    .new h2 span {
        color: #bbb8b5;
        font-size: 17px;
        padding-left: 17px;
    }

    .new .list01 {
        width: 380px;
        float: left;
    }

    .new .list02 {
        width: 380px;
        float: left;
        margin: 0 28px;
    }

    .new .list03 {
        width: 380px;
        float: right;
    }

    .new ul {
        padding-top: 10px;
    }

    .new ul li {
        font-size: 12px;
        padding: 14px 0;
        border-bottom: 1px #ccc dotted;
    }

    .new ul li .li_title {
        color: #0dc8ff;
        padding-right: 10px;
    }

    .new ul li .content:hover {
        color: #f00;
    }

    .new ul li .time {
        color: #ccc;
        float: right;
    }
</style>
